import WdmService from '../wdm';
import { ClsWdmrForever } from "./wdm";

class ClsWdmrManage {
  static get_related_wdmr_list = function(parent_wdm, child_wdm, relation_type) {
    return new Promise((resolve, reject) => {
      let data = {
        'parent_wdm': parent_wdm,
        'child_wdm': child_wdm,
        'relation_type': relation_type
      };
      WdmService.get_related_wdmr_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            resolve(resp.data.result);
          } else {
            reject(resp.data.message);
          }
        });
    });
  };

  static prepare_new_wisdom_data = function(d_wisdomData, data_type) {
    d_wisdomData.data_type = data_type;
    d_wisdomData.wisdom_op = "new";
    let pre_data = {
      'id': 'new',
      'data': {},
      'type': data_type,
      'reference_wdm': {},
      'parent': data_type
    };
    pre_data['reference_wdm']['version'] = d_wisdomData.option_data[data_type]['version'];
    pre_data['reference_wdm']['owner_type'] = d_wisdomData.option_data[data_type]['owner_type'];
    if (d_wisdomData.option_data[data_type]['owner_type'] === 'wisdom') {
      pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + data_type + '_v' + d_wisdomData.option_data[data_type].version;
    }
    for (let i in d_wisdomData.option_data[data_type].param_group.param_order) {
      pre_data['data'][d_wisdomData.option_data[data_type].param_group.param_order[i]] = {};
    }
    d_wisdomData.wisdom_data = pre_data;
  };

  static save_child_wdmr = function(d_wisdomData, parent_wdm, child_wdm, relation_type) {
    return new Promise((resolve, reject) => {
      let return_data = ClsWdmrForever.turn(d_wisdomData.wisdom_data, d_wisdomData.option_data[d_wisdomData.data_type], ['name', 'delete']);
      d_wisdomData.wisdom_data.label = return_data.name;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        reject('Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.');
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        reject('Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.\n' + require_txt);
      }
      let data = {
        'data': d_wisdomData.wisdom_data,
        'child_wdm': child_wdm,
        'parent_wdm': parent_wdm,
        'relation_type': relation_type
      };
      WdmService.save_child_wdmr(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            d_wisdomData.show = false;
            resolve(resp.data.result);
          } else {
            reject(resp.data.message);
          }
        });
    });
  };


}

export { ClsWdmrManage };
